import * as React from 'react';
import MaterialTable from "@material-table/core";
import { IJobDetailed, JobLink, JobOutcome, JobState, useJobActions, useJobReport } from '../Model/Job';
import { createDateColumn } from '../utils/DateUtils';
import { TextField, Table, TableCell, Typography, Button, Grid, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CopyToClipboard from 'react-copy-to-clipboard';
import FilterList from '@mui/icons-material/FilterList';
import { useSnackbar } from 'notistack';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

export interface IJobOverviewProps {
    pageSize: number;
    pageSizeOptions: number[];
}

export default function JobsOverview(props: IJobOverviewProps) {
    const { pageSize = 20, pageSizeOptions = [10, 20, 30] } = props;

    const jobActions = useJobActions();
    const jobReport = useJobReport();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const [expanded, setExpanded] = React.useState(false);
    const [filter, setFilter] = React.useState({
        useFilter: false,

        jobIdTerm: "",
        userEmailTerm: "",
        userIdTerm: "",

        filterUnsubmitted: true,
        filterActive: true,
        filterSuccess: true,
        filterFailed: true,
        filterCancelled: true,

        filterByCreationDate: false,
        creationStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
        creationEndDate: new Date(),

        filterByStartDate: false,
        startStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
        startEndDate: new Date(),

        filterByEndDate: false,
        endStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
        endEndDate: new Date(),
    });

    function inclusiveStartDate(date: Date): Date {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return (date);
    }

    function inclusiveEndDate(date: Date): Date {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return (date);
    }

    function getFilteredData(): IJobDetailed[] {
        var data: IJobDetailed[] = [];
        if (jobReport.hasData)
            data = jobReport.data!;

        if (filter.useFilter) {
            if (filter.jobIdTerm.length > 0)
                data = data.filter((i) => i.id.toLowerCase().includes(filter.jobIdTerm.toLowerCase()));
            if (filter.userEmailTerm.length > 0)
                data = data.filter((i) => i.email.toLowerCase().includes(filter.userEmailTerm.toLowerCase()));

            data = data.filter((i) => (i.state === JobState.Active && filter.filterActive) ||
                (i.executionInformation?.outcome === JobOutcome.Success && filter.filterSuccess) ||
                (i.executionInformation?.outcome === JobOutcome.Failed && filter.filterFailed) ||
                (i.executionInformation?.outcome === JobOutcome.Cancelled && filter.filterCancelled) ||
                (i.state === JobState.Unsubmitted && filter.filterUnsubmitted));

            //apply date filters
            if (filter.filterByCreationDate) {
                data = data.filter((i) => new Date(i.createdDateTime) >= inclusiveStartDate(filter.creationStartDate));
                data = data.filter((i) => new Date(i.createdDateTime) <= inclusiveEndDate(filter.creationEndDate));
            }

            if (filter.filterByStartDate) {
                data = data.filter((i) => new Date(i.executionInformation?.startedDateTime!) >= inclusiveStartDate(filter.startStartDate));
                data = data.filter((i) => new Date(i.executionInformation?.startedDateTime!) <= inclusiveEndDate(filter.startEndDate));
            }

            if (filter.filterByEndDate) {
                data = data.filter((i) => new Date(i.executionInformation?.endedDateTime!) >= inclusiveStartDate(filter.endStartDate));
                data = data.filter((i) => new Date(i.executionInformation?.endedDateTime!) <= inclusiveEndDate(filter.endEndDate));
            }
        }
        return data;
    }

    function refreshJobTimeSpan() {
        //find which of the start dates is the oldest
        var oldestDate: Date = (filter.creationStartDate.valueOf() < filter.startStartDate.valueOf()) ? filter.creationStartDate : filter.startStartDate;
        oldestDate = (oldestDate.valueOf() < filter.endStartDate.valueOf()) ? oldestDate : filter.endStartDate;

        //find which is the least
        var mostRecentDate: Date = (filter.creationEndDate.valueOf() > filter.startEndDate.valueOf()) ? filter.creationStartDate : filter.startStartDate;
        mostRecentDate = (mostRecentDate.valueOf() > filter.endEndDate.valueOf()) ? mostRecentDate : filter.endEndDate;

        jobReport.SetTimespan({
            startDate: oldestDate,
            endDate: mostRecentDate
        });
    }

    function applyAndClearButton() {
        if (filter.useFilter) {
            return (
                <Button variant="contained" color="primary" disableElevation onClick={(event) => handleTurnOffFilter(event)}>
                    Clear
                </Button>
            )
        }
        else {
            return (
                <Button variant="contained" color="secondary" disableElevation onClick={(event) => handleTurnOnFilter(event)}>
                    Search
                </Button>
            )
        }
    }

    const handleTurnOnFilter = (props: any) => {
        setFilter({ ...filter, useFilter: true });
        refreshJobTimeSpan();
    };

    const handleTurnOffFilter = (props: any) => {
        setFilter({
            useFilter: false,

            jobIdTerm: "",
            userEmailTerm: "",
            userIdTerm: "",

            filterUnsubmitted: true,
            filterActive: true,
            filterSuccess: true,
            filterFailed: true,
            filterCancelled: true,

            filterByCreationDate: false,
            creationStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
            creationEndDate: new Date(),

            filterByStartDate: false,
            startStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
            startEndDate: new Date(),

            filterByEndDate: false,
            endStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
            endEndDate: new Date(),
        })
        jobReport.SetTimespan({
            startDate: new Date(Date.now() - thirtyDaysInMilliseconds),
            endDate: new Date()
        });
    };

    const handleFilterChange = (prop: any) => (event: { target: { value: any; }; }) => {
        setFilter({ ...filter, [prop]: event.target.value });
    };

    const handleCheckboxChange = (event: any) => {
        setFilter({ ...filter, [event.target.name]: event.target.checked });
    };

    const handleCreationStartDateChange = (date: any) => {
        setFilter({ ...filter, creationStartDate: date, creationEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleCreationEndDateChange = (date: any) => {
        setFilter({ ...filter, creationEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleStartStartDateChange = (date: any) => {
        setFilter({ ...filter, startStartDate: date, startEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleStartEndDateChange = (date: any) => {
        setFilter({ ...filter, startEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleEndStartDateChange = (date: any) => {
        setFilter({ ...filter, endStartDate: date, endEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleEndEndDateChange = (date: any) => {
        setFilter({ ...filter, endEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            handleTurnOnFilter(e);
        }
    }

    function stateFilters() {
        return (
            <Grid container spacing={1} style={{ width: 320 }}>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterUnsubmitted} onChange={handleCheckboxChange} name="filterUnsubmitted" />}
                        label="Unsubmitted"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterActive} onChange={handleCheckboxChange} name="filterActive" />}
                        label="Active"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterSuccess} onChange={handleCheckboxChange} name="filterSuccess" />}
                        label="Success"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterCancelled} onChange={handleCheckboxChange} name="filterCancelled" />}
                        label="Cancelled"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterFailed} onChange={handleCheckboxChange} name="filterFailed" />}
                        label="Failed"
                    />
                </Grid>
            </Grid>
        )
    }

    function dateFilters() {
        return (
            <Grid container spacing={1} alignItems='center' style={{ width: 320 }}>
                <Grid item xs={2} alignContent='center'>
                    <Checkbox checked={filter.filterByCreationDate} onChange={handleCheckboxChange} name="filterByCreationDate" />
                </Grid>
                <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            format="dd/MM/yyyy"
                            label="Creation"
                            disabled={!filter.filterByCreationDate}
                            value={filter.creationStartDate}
                            onChange={handleCreationStartDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            format="dd/MM/yyyy"
                            label=" "
                            disabled={!filter.filterByCreationDate}
                            value={filter.creationEndDate}
                            onChange={handleCreationEndDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2} alignContent='center'>
                    <Checkbox checked={filter.filterByStartDate} onChange={handleCheckboxChange} name="filterByStartDate" />
                </Grid>
                <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            format="dd/MM/yyyy"
                            label="Start"
                            disabled={!filter.filterByStartDate}
                            value={filter.startStartDate}
                            onChange={handleStartStartDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            format="dd/MM/yyyy"
                            label=" "
                            disabled={!filter.filterByStartDate}
                            value={filter.startEndDate}
                            onChange={handleStartEndDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2} alignContent='center'>
                    <Checkbox checked={filter.filterByEndDate} onChange={handleCheckboxChange} name="filterByEndDate" />
                </Grid>
                <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            format="dd/MM/yyyy"
                            label="End"
                            disabled={!filter.filterByEndDate}
                            value={filter.endStartDate}
                            onChange={handleEndStartDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            format="dd/MM/yyyy"
                            label=" "
                            disabled={!filter.filterByEndDate}
                            value={filter.endEndDate}
                            onChange={handleEndEndDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        )
    }

    function filterBar() {
        if (expanded) {
            return (
                <Grid container spacing={1} style={{ width: 320 }}>
                    <Grid item xs={5}>
                        <Typography variant="h5">Filters </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="primary" disableElevation onClick={(event) => handleTurnOffFilter(event)}>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="secondary" disableElevation onClick={(event) => handleTurnOnFilter(event)}>
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button color="primary" onClick={(event) => setExpanded(false)}>
                            <ChevronLeftIcon fontSize="large" />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={filter.jobIdTerm} fullWidth label="Job Id" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleFilterChange('jobIdTerm')} />
                    </Grid>
                    {stateFilters()}
                    {dateFilters()}
                    <Grid item xs={12}>
                        <TextField value={filter.userEmailTerm} fullWidth label="Email" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleFilterChange('userEmailTerm')} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={filter.userIdTerm} fullWidth label="User Id" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleFilterChange('userIdTerm')} />
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <Grid container spacing={0} style={{ width: 40 }}>
                    <Grid item xs={12}>
                        <Button color="primary" onClick={(event) => setExpanded(true)}>
                            <FilterList fontSize="large" />
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    }

    function CopyButton() {
        var s = JSON.stringify(getFilteredData() ?? []);
        const { enqueueSnackbar } = useSnackbar();
        return (<CopyToClipboard text={s} onCopy={() => enqueueSnackbar("JSON copied!", { variant: "success" })}>
            <Tooltip title="Will include jobs filtered out by table search" placement="right" arrow>
                <Button startIcon={<FileCopyOutlinedIcon />} variant="contained" color="secondary" size="small" disableElevation>Copy JSON</Button>
            </Tooltip>
        </CopyToClipboard>)
    }

    return (
        <div>
            <Table size="small" >
                <TableCell>
                    {filterBar()}
                </TableCell>
                <TableCell>
                    <MaterialTable<IJobDetailed>
                        title={<div><Typography variant="h5">Jobs&nbsp;{CopyButton()}</Typography></div>}
                        isLoading={jobReport.isFetching}
                        columns={[
                            { title: "Name", field: "name", sorting: true, render: rowData => <JobLink job={rowData} /> },
                            { title: "Type", field: "type", sorting: true },
                            { title: "State", field: "state", sorting: true },
                            { title: "Email", field: "email" as any, sorting: true },
                            createDateColumn("Creation", "createdDateTime"),
                            createDateColumn("Start", "executionInformation.startedDateTime"),
                            createDateColumn("End", "executionInformation.endedDateTime", 'desc'),
                            { title: "location", field: "dataCenter.location" as any, sorting: true },
                        ]}
                        data={getFilteredData() ?? []}
                        actions={[
                            ({
                                tooltip: 'Refresh jobs',
                                icon: 'refresh',
                                isFreeAction: true,
                                onClick: () => jobReport.refresh()
                            }),
                            rowData => ({
                                tooltip: rowData.state !== JobState.Unsubmitted ? 'Download logs' : undefined,
                                icon: 'get_app',
                                disabled: rowData.state === JobState.Unsubmitted,
                                onClick: (event, rowData) => jobActions.downloadLogs(rowData)
                            }),
                            rowData => ({
                                tooltip: rowData.state === JobState.Active ? 'Terminate Job' : undefined,
                                icon: 'cancel',
                                disabled: rowData.state !== JobState.Active,
                                onClick: (event, rowData) => jobActions.terminate(rowData)
                            }),
                        ]}
                        options={{
                            filtering: false,
                            pageSize: pageSize,
                            pageSizeOptions: pageSizeOptions,
                            search: true,
                            sorting: true,
                            headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                            padding: 'dense'
                        }}
                    />
                </TableCell>
            </Table>
        </div>
    );

}


