import MaterialTable from "@material-table/core";
import { Table, TableCell, Typography } from '@mui/material';
import { DetectorState, useDetectorActions, useDetectors } from '../Model/Detector';
import { toArray } from "../utils/Utils";

export interface IDetectorsOverviewProps {
    pageSize: number;
    pageSizeOptions: number[];
}

// Flatten the detector and it's version so we can have 1 line per version
interface IDetectorUniqueVersion {
    name: string,
    description: string,
    type: string,
    documentationUrl: string,
    creationDate: string,
    version: string,
    status: DetectorState,
}

export default function DetectorsOverview(props: IDetectorsOverviewProps) {
    const { pageSize = 20, pageSizeOptions = [10, 20, 30] } = props;
    const detectorActions = useDetectorActions();
    const detectors = useDetectors();

    function getFilteredData(): IDetectorUniqueVersion[] {
        var data: IDetectorUniqueVersion[] = [];
        if (detectors.hasData)
        {
            detectors.data?.detectors.forEach(detector => {
                detector.versions.forEach(version => {
                    const uniqueVersion: IDetectorUniqueVersion = {
                        ...detector,
                        ...version
                    };
                    data.push(uniqueVersion);
                });
            });
        }

        return data;
    }


    return (
        <div>
            <Table size="small" >
                <TableCell>
                    <MaterialTable<IDetectorUniqueVersion>
                        title={<div><Typography variant="h5">Public Detectors Management</Typography></div>}
                        isLoading={detectors.isFetching}
                        columns={[
                            { title: "Name", field: "name", sorting: true },
                            { title: "Description", field: "description", sorting: true },
                            { title: "Type", field: "type", sorting: true },
                            { title: "Version", field: "version", sorting: true },
                        ]}
                        data={getFilteredData() ?? []}
                        actions={[
                            ({
                                tooltip: 'Refresh detector',
                                icon: 'refresh',
                                isFreeAction: true,
                                onClick: () => detectors.refresh()
                            }),
                            rowData => ({
                               tooltip: rowData.status === DetectorState.Ready && !rowData.name.startsWith("@") ? 'Publish' : undefined,
                               icon: 'public',
                               disabled: rowData.status !== DetectorState.Ready || rowData.name.startsWith("@"),
                               onClick: (event, rowData) => {
                                toArray(rowData).forEach(singleRow => {
                                    detectorActions.publish(singleRow.name, singleRow.version);
                                });
                               }
                            }),
                            rowData => ({
                               tooltip: rowData.name.startsWith("@") ? 'Unpublish' : undefined,
                               icon: 'delete',
                               disabled: !rowData.name.startsWith("@") ,
                               onClick: (event, rowData) => {
                                toArray(rowData).forEach(singleRow => {
                                    detectorActions.unpublish(singleRow.name, singleRow.version);
                                });
                                }
                            }),
                        ]}
                        options={{
                            filtering: false,
                            pageSize: pageSize,
                            pageSizeOptions: pageSizeOptions,
                            headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                            padding: 'dense'
                        }}
                    />
                </TableCell>
            </Table>
        </div>
    );

}
