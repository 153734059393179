import * as React from 'react';
import { Link } from "react-router-dom";
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Dashboard from '@mui/icons-material/Dashboard';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Cloud from '@mui/icons-material/Cloud';
import Work from '@mui/icons-material/Work';
import Build from '@mui/icons-material/Build';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Power from '@mui/icons-material/Power';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DeleteIcon from '@mui/icons-material/Delete';
import Devices from '@mui/icons-material/Devices';
import CancelOutlineIcon from '@mui/icons-material/CancelOutlined';
import { SmartToy } from '@mui/icons-material';
const classNames = require('classnames');

export interface IDrawerState {
    opened: boolean;
    toolsOpen: boolean;
    azureResourcesOpen: boolean;
}

export interface IDrawerProps {
    classes?: any;
}

class DrawerList extends React.Component<IDrawerProps, IDrawerState> {

    constructor(props: IDrawerProps) {
        super(props);
        this.state = {
            opened: true,
            toolsOpen: false,
            azureResourcesOpen: false
        };
    }

    handleToolsClicked = () => {
        this.setState({ toolsOpen: !this.state.toolsOpen });
    };

    handleDrawerClick = () => {
        this.setState({ opened: !this.state.opened });
    };

    handleAzureResourcesClicked = () => {
        this.setState({ azureResourcesOpen: !this.state.azureResourcesOpen });
    }

    renderItemText(name: string) {
        const { classes } = this.props;
        return (
            this.state.opened &&
            <Box display={{ xs: 'none', sm: 'block' }}>
                <ListItemText className={classes.listItemText} primary={name} /></Box>
        );
    }

    renderLinkItem(link: string, iconName: any, name: string) {
        const { classes } = this.props;
        return (
            <Link to={link} className={classes.link}>
                <ListItemButton>
                    <ListItemIcon className={classes.itemIcon}>
                        {iconName}
                    </ListItemIcon>
                    {this.renderItemText(name)}
                </ListItemButton>
            </Link>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <Drawer className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classNames(classes.drawerPaper, !this.state.opened && classes.drawerPaperClose),
                }}
                open={this.state.opened}>
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={this.handleDrawerClick} size='small'>
                        {this.state.opened ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <List className={classes.root}>
                    {this.renderLinkItem("/admin/dashboard", <Dashboard />, "Dashboard")}
                    {this.renderLinkItem("/admin/clusters", <Cloud />, "Clusters")}
                    {this.renderLinkItem("/admin/jobs", <Work />, "Jobs")}
                    {this.renderLinkItem("/admin/detectors", <SmartToy />, "Detectors")}
                    {this.renderLinkItem("/admin/statistics", <EqualizerIcon />, "Statistics")}
                    <ListItemButton onClick={this.handleToolsClicked}>
                        <ListItemIcon className={classes.itemIcon}>
                            <Build />
                        </ListItemIcon>
                        {this.renderItemText("Tools")}
                        {this.state.opened && (this.state.toolsOpen ?
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandLess /></Box> :
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandMore /></Box>)}
                    </ListItemButton>
                    <Collapse in={this.state.toolsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to="/admin/tools/submitjob" className={classes.link}>
                                <ListItemButton className={classes.nested}>
                                    <ListItemIcon className={classes.itemIcon}>
                                        <FitnessCenter />
                                    </ListItemIcon>
                                    {this.state.opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <ListItemText className={classes.listItemText} primary="Submit Jobs" />
                                        </Box>}
                                </ListItemButton>
                            </Link>
                            <Link to="/admin/tools/copytoken" className={classes.link}>
                                <ListItemButton className={classes.nested}>
                                    <ListItemIcon className={classes.itemIcon}>
                                        <Power />
                                    </ListItemIcon>
                                    {this.state.opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <ListItemText className={classes.listItemText} primary="Copy Token" />
                                        </Box>}
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={this.handleAzureResourcesClicked}>
                        <ListItemIcon className={classes.itemIcon}>
                            <Devices />
                        </ListItemIcon>
                        {this.renderItemText("Azure Resources")}
                        {this.state.opened && (this.state.azureResourcesOpen ?
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandLess /></Box> :
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandMore /></Box>)}
                    </ListItemButton>
                    <Collapse in={this.state.azureResourcesOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to="/admin/azureResources/dlqmessages" className={classes.link}>
                                <ListItemButton className={classes.nested}>
                                    <ListItemIcon className={classes.itemIcon}>
                                        <DeleteIcon />
                                    </ListItemIcon>
                                    {this.state.opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <ListItemText className={classes.listItemText} primary="DLQ Messages" />
                                        </Box>}
                                </ListItemButton>
                            </Link>
                            <Link to="/admin/azureResources/invalidjobsfrompools" className={classes.link}>
                                <ListItemButton className={classes.nested}>
                                    <ListItemIcon className={classes.itemIcon}>
                                        <CancelOutlineIcon />
                                    </ListItemIcon>
                                    {this.state.opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <ListItemText className={classes.listItemText} primary="Invalid Jobs" />
                                        </Box>}
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                </List>
            </Drawer >
        );
    }
}

const drawerStyle = (theme: Theme) => ({
    drawer: {
        height: '100vh',
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        textDecoration: 'none'
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    drawerPaper: {
        position: "relative" as 'relative',
        whiteSpace: 'nowrap' as 'nowrap',
    },
    drawerPaperClose: {
        overflowX: 'hidden' as 'hidden',
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    itemIcon: {
        minWidth: 0
    },
    listItemText: {
        paddingLeft: theme.spacing(1)
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    }
});

export default withStyles(drawerStyle)(DrawerList);
