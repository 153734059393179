import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Table, TableContainer, TableCell, TableRow, TableBody,
    Typography
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { IClusterInfo } from "./Clusters";
import { useFetchApi } from '../utils/UseFetchApi';
import CenteredProgress from './CenteredProgress';
import { red, orange, grey, blue, green } from '@mui/material/colors';
import Flag from 'react-world-flags'

// Link to cluster details page
export function ClusterLink(props: { cluster: IClusterInfo }) {
    return (
        <Link to={{ pathname: `/admin/clusters/${props.cluster.location}/${props.cluster.name}` }} style={{ textDecoration: 'none' }}>{props.cluster.name}</Link>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { backgroundColor: theme.palette.background.paper, },
        spacing: { padding: theme.spacing(1), },
        table: {},
        row: {
            border: '0px'
        },
        cell: {
            border: '0px'
        }
    }),
);

interface IClusterRow {
    label: string;
    fill: number | string;
    style?: any;
}

export function clusterHasTrouble(cluster: IClusterInfo)
{
    const workingNodes = cluster.nodeStatistics.running + cluster.nodeStatistics.idle;
    if (workingNodes > 0)
    {
        return false;
    }
    const issueNodes = cluster.nodeStatistics.unusable + cluster.nodeStatistics.start_task_failed;
    return (issueNodes > 0);
}

function ClusterInfoDisplay(props : {cluster: IClusterInfo}) {
    const classes = useStyles();

    function regionFlag(location: string)
    {
        if (location === "EastUs")
        {
            return (<Flag code="US" />)
        }
        if (location === "NorthEurope")
        {
            return (<Flag code="EU" />)
        }
        if (location === "SoutheastAsia")
        {
            return (<Flag code="SG" />)
        }
        if (location === "JapanEast")
        {
            return (<Flag code="JP" />)
        }
        if (location === "AustraliaEast")
        {
            return (<Flag code="AU" />)
        }
        return (<Flag code="FR" />);
    }

    function showWarning(cluster: IClusterInfo)
    {
        if (clusterHasTrouble(cluster))
            return (<Typography variant="h5" style={{ color: orange[700] }}>Cluster is having trouble!</Typography>)
        return (<Typography variant="h5" style={{ color: blue[400] }}>Cluster is fine</Typography>)
    }

    function createRow(clusterRow: IClusterRow)
    {
        return (
            <TableRow className={classes.row}>
                <TableCell className={classes.cell} style={clusterRow.style ? clusterRow.style : {}}>
                    <strong>{clusterRow.label}</strong>
                </TableCell>
                <TableCell className={classes.cell} style={clusterRow.style ? clusterRow.style : {}}>
                    {clusterRow.fill}
                </TableCell>
            </TableRow>
        );
    }

    function clusterStatus(cluster: IClusterInfo)
    {
        const clusterRows: IClusterRow[] = [{label: "Active jobs:", fill: cluster.jobStatistics.active},
            {label: "Completed jobs:", fill: cluster.jobStatistics.completed},
            {label: "Dedicated nodes:", fill: cluster.dedicatedNodes},
            {label: "Low-priority nodes:", fill: cluster.lowPriorityNodes},
            {label: "State:", fill: cluster.state}];

        return (
            <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <TableRow className={classes.row}>
                        <Typography variant="h6">
                            Status:
                        </Typography>
                    </TableRow>
                    {clusterRows.map((clusterRow) => createRow(clusterRow))}
                </TableBody>
            </Table>
        );
    }

    function getColor(i: number, type: string)
    {
        if (i === 0)
        {
            return grey[500];;
        }
        switch (type)
        {
            case "start_task_failed":
                return orange[500];
            case "unusable":
                return red[900];
            case "running":
                return green[800];
            case "idle":
                return grey[800]
            default:
                return blue[600];
        }
    }

    function nodeStatus(cluster: IClusterInfo)
    {
        const nodeRows: IClusterRow[] = [{label: "Idle:", fill: cluster.nodeStatistics.idle, style: {color: getColor(cluster.nodeStatistics.idle, "idle")}},
            {label: "Running:", fill: cluster.nodeStatistics.running, style: {color: getColor(cluster.nodeStatistics.running, "running")}},
            {label: "Starting:", fill: cluster.nodeStatistics.starting, style: {color: getColor(cluster.nodeStatistics.starting, "")}},
            {label: "Waiting for start task:", fill: cluster.nodeStatistics.start_task, style: {color: getColor(cluster.nodeStatistics.start_task, "")}},
            {label: "Start task failed:", fill: cluster.nodeStatistics.start_task_failed, style: {color: getColor(cluster.nodeStatistics.start_task_failed, "start_task_failed")}},
            {label: "Leaving:", fill: cluster.nodeStatistics.leaving, style: {color: getColor(cluster.nodeStatistics.leaving, "")}},
            {label: "Unusable", fill: cluster.nodeStatistics.unusable, style: {color: getColor(cluster.nodeStatistics.unusable, "unusable")}},
            {label: "Preempted:", fill: cluster.nodeStatistics.preempted, style: {color: getColor(cluster.nodeStatistics.preempted, "")}},
            {label: "Rebooting:", fill: cluster.nodeStatistics.rebooting, style: {color: getColor(cluster.nodeStatistics.rebooting, "")}},
            {label: "Other:", fill: cluster.nodeStatistics.other, style: {color: getColor(cluster.nodeStatistics.other, "")}}];


        return (
            <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <TableRow className={classes.row}>
                        <Typography variant="h6">
                            Nodes:
                        </Typography>
                    </TableRow>
                    {nodeRows.map((statusRow) => createRow(statusRow))}
                </TableBody>
            </Table>
        );
    }

    return (
        <div className={classes.spacing}>
            <TableContainer >
                <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }}>
                    <TableCell className={classes.cell}>
                        <Typography style={{ width: 50 }}>
                            {regionFlag(props.cluster.location)}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <Typography variant="h4">
                            <b>{props.cluster.name}</b> - {props.cluster.location}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {showWarning(props.cluster)}
                    </TableCell>
                </Table>
                <Table className={classes.table} size="small">
                    <TableCell className={classes.cell}>
                        {clusterStatus(props.cluster)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {nodeStatus(props.cluster)}
                    </TableCell>
                </Table>
            </TableContainer>
        </div>
    );
}
export default function ClusterDetails() {
    const classes = useStyles();
    const { location, name } = useParams<{ location: string, name: string }>(); // using useParams hook

    const clusterFetch = useFetchApi<IClusterInfo>(window.location.origin + `/api/v1/clusters/locations/${location}/${name}`);

    React.useEffect(() => {
        clusterFetch.run();
    }, []);

    if (clusterFetch.isFetching)
        return (<CenteredProgress />);

    if (clusterFetch.error)
        return (<div>{`${clusterFetch.error}`}</div>);

    if (!clusterFetch.data)
        return (<div>{`Cluster ${name}-${location} not found`}</div>);

    return (
        <div className={classes.root}>
            <div className={classes.spacing}>
                <ClusterInfoDisplay cluster={clusterFetch.data} />
            </div>
        </div>
    );
}
