import * as React from 'react';
import { useFetchApi } from '../utils/UseFetchApi';
import { useSnackbar } from 'notistack';

const baseUrl: string = "/api/v2/detectors/";

export enum DetectorState
{
    AwaitingData = "AwaitingData",
    Ready = "Ready",
}

export interface IDetectorVersion {
    creationDate: string,
    version: string,
    status: DetectorState,
}
export interface IDetector {
    name: string,
    description: string,
    type: string,
    documentationUrl: string,
    versions: IDetectorVersion[],
}

export interface IDetectorPagedResponse {
    detectors: IDetector[]
}

export function useDetectors() {
    const fetchApi = useFetchApi<IDetectorPagedResponse>(getUrl());
    const [ initialFetch, setInitialFetch] = React.useState<boolean>(false);

    function getUrl() {
        const url = new URL(baseUrl, window.location.origin);
        return url.href;
    }

    function refresh() {
        fetchApi.run();
    }

    // run once at startup
    React.useEffect(() => {
        if (!initialFetch)
        {
            setInitialFetch(true);
            fetchApi.run();
        }
    }, [fetchApi, initialFetch, setInitialFetch]);

    return {
        data: fetchApi.data,
        hasData: fetchApi.hasData,
        refresh,
        isFetching: fetchApi.isFetching,
        fetchCount: fetchApi.fetchCount,
        error: fetchApi.error
    }
}

export function useDetectorActions() {
   const { enqueueSnackbar } = useSnackbar();
   const publishApi = useFetchApi<any>();
   const unpublishApi = useFetchApi<any>();

   function publish(name: string, version: string) {
        var result = window.confirm(`Are you sure you want to publish '${name}-${version}' ?"`);
        if (!result)
            return;

        enqueueSnackbar(`Publishing '${name}-${version}' ...`, { variant: "info" });

        publishApi.run(window.location.origin + baseUrl + name + "/version/" + version + "/publish",
            {
                method: 'POST'
            })
            .then(result =>
            {
                enqueueSnackbar(`Detector '${name}'-'${version}' is now public!`, { variant: "success" })
            })
            .catch(error => {
                enqueueSnackbar(`Failed to publish '${name}'-'${version}' : ${error.toString()}`, { variant: "error" });
            });
   }

   function unpublish(name: string, version: string) {
    var result = window.confirm(`Are you sure you want to unpublish '${name}-${version}' ?"`);
    if (!result)
        return;

      unpublishApi.run(window.location.origin + baseUrl + name + "/version/" + version + "/unpublish", {
        method: 'POST'
      })
            .then(result => {
                enqueueSnackbar(`Detector '${name}-${version}' have been removed from the public list.`, { variant: "success" });
            })
            .catch(error => {
                enqueueSnackbar(`Failed to unpublish '${name}-${version}': ${error.toString()}`, { variant: "error" });
            })
   }

   return {
    publish,
    unpublish
   }
}
