import { InvalidJobsFromPools } from "realityservices-ui-components";

export default function InvalidJobsFromBatch() {
    const locationOptions = [
        { value: "EastUs", label: "East US" },
        { value: "CentralIndia", label: "Central India" },
        { value: "AustraliaEast", label: "Australia East" },
        { value: "NorthEurope", label: "North Europe" },
        { value: "SoutheastAsia", label: "Southeast Asia" },
        { value: "UKSouth", label: "UK South" },
        { value: "JapanEast", label: "Japan East" },
    ];

    function handleDelete(job: any) {
        const jobLocation = job.dataCenter ? job.dataCenter.location.toString() : job.location;
        return `${window.location.origin}/api/v1/clusters/jobId/${job.id.toString()}/locations/${jobLocation}/terminate`;
    }

    return (
        <InvalidJobsFromPools
            getInvalidJobReportUrl={
                window.location.origin + "/api/v1/clusters/invalidJobs"
            }
            getCancelJobUrl={handleDelete}
            locationFilterOptions={locationOptions}
        />
    );
}
