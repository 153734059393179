import * as React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Label, Legend } from 'recharts';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { useFetchApi } from '../utils/UseFetchApi';
import { arrayUnique } from '../utils/Utils';
import { useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import { Grid, Box, Button, IconButton } from '@mui/material';
import Title from './Title';
import CenteredProgress from './CenteredProgress';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


interface IEntreprise {
    ultimateSite: string,
    name: string
}

interface IOutcomesPerUser {
    email: string,
    userId: string,
    ultimateSite: string,
    count: number,
    countO2D: number,
    countS2D: number,
    succeeded: number,
    failed: number,
    cancelled: number,
    processingTime: number
}

interface IMonthlyStatistics {
    month: string,
    users: string[],
    internalUsers: string[],
    uniqueEntreprises: string[],
    numberOfJobs: number,
    outcomesPerUser: IOutcomesPerUser[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            overflow: "hidden"
        },
        gridBottomSpacing:
        {
            marginBottom: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            display: 'flex',
            //overflow: 'hidden',
            flexDirection: 'column',
            height: 420,
        },
        button: {
            margin: theme.spacing(3)
        }
    }),
);

const BentleyUltimateSite = "1001389117";

function createBox(fontsize: number, boxMargin: string, dataLabel: string, boxColor: string = "", dataValue: any = "") {
    if (Number.isNaN(dataValue)) {
        dataValue = 0;
    }
    const data = `${dataLabel} ${dataValue}`;
    return (<Box component="div" display="block" style={{ fontSize: fontsize, margin: boxMargin, color: boxColor }}>{data}</Box>);
}

function getJobsWithoutBentley(val: IMonthlyStatistics) {
    let numberOfOutsideJobs = 0;
    val.outcomesPerUser.forEach((outcomesPerUser) => {
        if (!val.internalUsers.find(user => user === outcomesPerUser.email)) {
            numberOfOutsideJobs += outcomesPerUser.count;
        }
    });
    return numberOfOutsideJobs;
}

function DisplayChart(props: any) {
    const jobDataKey = (val: IMonthlyStatistics) => {
        return (
            props.discardBentley ? getJobsWithoutBentley(val) :
                val.numberOfJobs
        );
    };

    const usersDataKey = (val: IMonthlyStatistics) => {
        return (
            props.discardBentley ? (val.users.length - val.internalUsers.length) :
                val.users.length
        );
    };

    return (
        props.statsReport.hasData ?
            <ResponsiveContainer debounce={100}>
                <LineChart
                    data={props.statsReport.data}
                    margin={{
                        top: 10, right: 10, left: 0, bottom: 10,
                    }}
                >
                    <Legend />
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" >
                        <Label value="Jobs" angle={-90} position="insideLeft" />
                    </YAxis>
                    <YAxis yAxisId="right" orientation="right">
                        <Label value="Users" angle={90} position="insideRight" />
                    </YAxis>
                    <Tooltip formatter={(value: string) => (value)} />
                    <Line yAxisId="left" type="monotone" name="Jobs" stroke="#00A0FF"
                        dataKey={jobDataKey} />
                    <Line yAxisId="right" type="monotone" name="Active Users" stroke="#FFA000"
                        dataKey={usersDataKey} />
                </LineChart>
            </ResponsiveContainer> :
            <CenteredProgress />
    );
}

export default function Statistics() {
    const nbMonthsBack = 6;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const statsReport = useFetchApi<IMonthlyStatistics[]>(`${window.location.origin}/api/v1/jobs/statistics?nbMonthsBack=${nbMonthsBack}`);

    const entreprisesApi = useFetchApi<IEntreprise[]>();

    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const [startDate, setStartDate] = React.useState(new Date(Date.now() - thirtyDaysInMilliseconds));
    const [endDate, setEndDate] = React.useState(new Date(Date.now()));
    const [startIndex, setStartIndex] = React.useState(nbMonthsBack - 2);
    const [endIndex, setEndIndex] = React.useState(nbMonthsBack - 1);
    const [discardBentley, setDiscardBentley] = React.useState(true);
    const [periodStats, setPeriodStats] = React.useState({
        hasStats: false,
        internalUsers: [""],
        entreprises: [""],
        allUsers: [""],
        jobs: 0,
        externalJobs: 0,
        succeeded: 0,
        outcomesPerUser: [{
            email: "",
            userId: "",
            ultimateSite: "",
            count: 0,
            countO2D: 0,
            countS2D: 0,
            succeeded: 0,
            cancelled: 0,
            failed: 0,
            processingTime: 0
        }]
    });

    function notifyError(message: string) {
        console.error(message);
        enqueueSnackbar(message, { variant: "error" });
    }

    async function getEntreprisesNames(entreprises: string[]) {
        enqueueSnackbar("Fetching entreprises names", { variant: "info" });
        let body_entreprises = JSON.stringify(entreprises);
        try {
            const entreprisesNames = await entreprisesApi.run(window.location.origin + "/api/v1/frontendhelper/entreprisesNames",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: body_entreprises
                });
            if (!entreprisesNames)
                throw Error("unknown error");

            enqueueSnackbar("Successfully Fetched Entreprises Names!", { variant: "success" });

            return entreprisesNames;
        }
        catch (error) {
            let errorMessage = "Fetching of Entreprises Names failed ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
        }
    }

    function getNumberOfJobsForEntreprise(entrepriseUltimateSite: string, outcomesPerUser: IOutcomesPerUser[]): number {
        let totalJobs: number = 0;
        outcomesPerUser.forEach((outcomePerUser) => {
            if (outcomePerUser.ultimateSite === entrepriseUltimateSite) {
                totalJobs += outcomePerUser.count;
            }
        });
        return totalJobs;
    }

    function getNumberOfJobsOfTypeForEntreprise(entrepriseUltimateSite: string, outcomesPerUser: IOutcomesPerUser[], jobType: string): number {
        let countJobs: number = 0;
        outcomesPerUser.forEach((outcomePerUser) => {
            if (outcomePerUser.ultimateSite === entrepriseUltimateSite) {
                if (jobType === "objects2D") {
                    countJobs += outcomePerUser.countO2D;
                }
                else if (jobType === "segmentation2D") {
                    countJobs += outcomePerUser.countS2D;
                }
            }
        });
        return countJobs;
    }

    function getEntrepriseProcessingTime(entrepriseUltimateSite: string, outcomesPerUser: IOutcomesPerUser[]): number {
        let totalProcessingTime: number = 0;
        outcomesPerUser.forEach((outcomePerUser) => {
            if (outcomePerUser.ultimateSite === entrepriseUltimateSite) {
                totalProcessingTime += outcomePerUser.processingTime;
            }
        });
        return totalProcessingTime;
    }

    function exportEntreprisesToCSV(entreprises: string[], month: string, outcomesPerUser: IOutcomesPerUser[]) {
        getEntreprisesNames(entreprises).then((entreprisesNames) => {
            if (entreprisesNames) {
                let csvContent = "data:text/csv;charset=utf-8,UltimateSite,Name,Nb of Jobs,O2D Jobs,S2D Jobs,Total Processing Time\n";
                entreprisesNames.forEach((e: IEntreprise) => {
                    if (e.ultimateSite !== BentleyUltimateSite || !discardBentley) {
                        csvContent += e.ultimateSite + "," +
                            e.name + "," +
                            getNumberOfJobsForEntreprise(e.ultimateSite, outcomesPerUser) + "," +
                            getNumberOfJobsOfTypeForEntreprise(e.ultimateSite, outcomesPerUser, "objects2D") + "," +
                            getNumberOfJobsOfTypeForEntreprise(e.ultimateSite, outcomesPerUser, "segmentation2D") + "," +
                            getEntrepriseProcessingTime(e.ultimateSite, outcomesPerUser) / 3600 + "\n"; // we want that time in hours
                    }
                });
                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                discardBentley ? link.setAttribute("download", "entreprises_" + month + "_withoutBentley" + ".csv") :
                    link.setAttribute("download", "entreprises_" + month + ".csv");
                document.body.appendChild(link);
                link.click();
            }
        }).catch(error => {
            console.error(error);
        });
    }

    function createExportEntrepriseButton(entreprises: string[], month: string, outcomesPerUser: IOutcomesPerUser[], classname: string) {
        return (
            <Button variant="contained" color="secondary" className={classname} onClick={() => exportEntreprisesToCSV(entreprises, month, outcomesPerUser)}>
                Export Entreprises
            </Button>
        );
    }

    function exportUsersToCSV(outcomesPerUser: IOutcomesPerUser[], month: string) {
        let csvContent = "data:text/csv;charset=utf-8,Email,UserId,UltimateSite,Nb of Jobs,Succeeded,Cancelled,Failed,O2D Jobs,S2D Jobs,Total Processing Time\n";
        outcomesPerUser.forEach((outcomePerUser) => {
            if (outcomePerUser.ultimateSite !== BentleyUltimateSite || !discardBentley) {
                csvContent += outcomePerUser.email + "," +
                    outcomePerUser.userId + "," +
                    outcomePerUser.ultimateSite + "," +
                    outcomePerUser.count + "," +
                    outcomePerUser.succeeded + "," +
                    outcomePerUser.cancelled + "," +
                    outcomePerUser.failed + "," +
                    outcomePerUser.countO2D + "," +
                    outcomePerUser.countS2D + "," +
                    outcomePerUser.processingTime / 3600 + "\n"; // we want that time in hours
            }
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        discardBentley ? link.setAttribute("download", "users_" + month + "_withoutBentley" + ".csv") :
            link.setAttribute("download", "users_" + month + ".csv");
        document.body.appendChild(link);
        link.click();
    }

    function createExportUsersButton(outcomesPerUser: IOutcomesPerUser[], month: string, classname: string) {
        return (
            <Button variant="contained" color="secondary" className={classname} onClick={() => exportUsersToCSV(outcomesPerUser, month)}>
                Export Users
            </Button>
        );
    }

    const handleStartDateChange = (date: any) => {
        const newStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
        setStartDate(newStartDate);
        const sIndex = nbMonthsBack - (12 * (new Date(Date.now()).getFullYear() - newStartDate.getFullYear()) +
            (new Date(Date.now()).getMonth() - newStartDate.getMonth())) - 1;
        setStartIndex(sIndex < 0 ? 0 : sIndex);
    };

    const handleEndDateChange = (date: any) => {
        const newEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setEndDate(newEndDate);
        const eIndex = nbMonthsBack - (12 * (new Date(Date.now()).getFullYear() - newEndDate.getFullYear()) +
            (new Date(Date.now()).getMonth() - newEndDate.getMonth())) - 1;
        setEndIndex(eIndex);
    };

    function getJobsWithOutcome(outcomes: IOutcomesPerUser[], outcome: string) {
        return outcomes.reduce((total: number, stat: any) => {
            if (!discardBentley || stat["ultimateSite"] !== BentleyUltimateSite) {
                return total + stat[outcome];
            }
            else {
                return total;
            }
        }, 0);
    }

    function getJobsOfType(outcomes: IOutcomesPerUser[], jobTypeCount: string) {
        return outcomes.reduce((total: number, stat: any) => {
            if (!discardBentley || stat["ultimateSite"] !== BentleyUltimateSite) {
                return total + stat[jobTypeCount];
            }
            else {
                return total;
            }
        }, 0);
    }

    const handleDiscardBentleyChange = () => {
        setDiscardBentley(!discardBentley);
    };

    function DiscardBentleyIcon() {
        if (discardBentley) {
            return (
                <div>
                    <Visibility />
                    <div style={{ fontSize: 12 }}>
                        Show Bentley Usage
                    </div>
                </div>);
        }
        else {
            return (
                <div>
                    <VisibilityOff />
                    <div style={{ fontSize: 12 }}>
                        Hide Bentley Usage
                    </div>
                </div>);
        }
    }

    React.useEffect(() => {
        statsReport.run();
    }, []);

    React.useEffect(() => {
        setPeriodStats({
            hasStats: false,
            internalUsers: [""],
            entreprises: [""],
            allUsers: [""],
            jobs: 0,
            externalJobs: 0,
            succeeded: 0,
            outcomesPerUser: [{
                email: "",
                userId: "",
                ultimateSite: "",
                count: 0,
                countO2D: 0,
                countS2D: 0,
                succeeded: 0,
                cancelled: 0,
                failed: 0,
                processingTime: 0
            }]
        });
    }, [startIndex, endIndex]);

    function submitPeriodStats() {
        if (endIndex < startIndex) {
            return;
        }
        else if (statsReport.data) {
            let tmpInternalUsers: string[] = [];
            let tmpEntreprises: string[] = [];
            let tmpAllUsers: string[] = [];
            let tmpJobs = 0;
            let tmpExternalJobs = 0;
            let tmpSucceeded = 0;
            let tmpOutcomesPerUser: IOutcomesPerUser[] = [];
            for (let i = startIndex; i <= endIndex; i++) {
                tmpInternalUsers = arrayUnique(tmpInternalUsers.concat(statsReport.data[i].internalUsers));
                tmpAllUsers = arrayUnique(tmpAllUsers.concat(statsReport.data[i].users));
                tmpJobs += statsReport.data[i].numberOfJobs;
                tmpEntreprises = arrayUnique(tmpEntreprises.concat(statsReport.data[i].uniqueEntreprises));
                tmpSucceeded += getJobsWithOutcome(statsReport.data[i].outcomesPerUser, "succeeded");

                statsReport.data[i].outcomesPerUser.forEach((outcomePerUser: IOutcomesPerUser) => {
                    if (outcomePerUser.ultimateSite !== BentleyUltimateSite) {
                        tmpExternalJobs += outcomePerUser.count;
                    }
                    let found = tmpOutcomesPerUser.find(o => o.email === outcomePerUser.email);

                    if (found !== undefined) {
                        found.count += outcomePerUser.count;
                        found.succeeded += outcomePerUser.succeeded;
                        found.cancelled += outcomePerUser.cancelled;
                        found.failed += outcomePerUser.failed;
                    }
                    else {
                        tmpOutcomesPerUser.push({
                            email: outcomePerUser.email,
                            userId: outcomePerUser.userId,
                            ultimateSite: outcomePerUser.ultimateSite,
                            count: outcomePerUser.count,
                            countO2D: outcomePerUser.countO2D,
                            countS2D: outcomePerUser.countS2D,
                            succeeded: outcomePerUser.succeeded,
                            cancelled: outcomePerUser.cancelled,
                            failed: outcomePerUser.failed,
                            processingTime: outcomePerUser.processingTime
                        });
                    }
                });
            }
            setPeriodStats({
                hasStats: true,
                internalUsers: tmpInternalUsers,
                allUsers: tmpAllUsers,
                jobs: tmpJobs,
                externalJobs: tmpExternalJobs,
                entreprises: tmpEntreprises,
                succeeded: tmpSucceeded,
                outcomesPerUser: tmpOutcomesPerUser
            });
        }
    }

    function DisplayPeriodStats() {
        const startMonthForName = startDate.getMonth() + 1;
        const endMonthForName = endDate.getMonth() + 1;
        return (
            periodStats.hasStats ?
                <div>
                    {
                        discardBentley ?
                            <div></div> :
                            createBox(15, "5px", "Bentley Users :", "black", periodStats.internalUsers.length)
                    }
                    {
                        discardBentley ?
                            createBox(15, "5px", "Entreprises", "black", getNonBentleyEntreprises(periodStats.entreprises).length) :
                            createBox(15, "5px", "Enterprises :", "black", periodStats.entreprises.length)
                    }
                    {createBox(15, "5px", "External Users :", "black", periodStats.allUsers.length - periodStats.internalUsers.length)}
                    {
                        discardBentley ?
                            createBox(15, "10px", "Jobs :", "black", periodStats.externalJobs) :
                            createBox(15, "10px", "Jobs :", "black", periodStats.jobs)
                    }
                    {
                        discardBentley ?
                            createBox(15, "10px", "% Success :", "black", parseFloat((periodStats.succeeded * 100 / periodStats.externalJobs).toFixed(2))) :
                            createBox(15, "10px", "% Success :", "black", parseFloat((periodStats.succeeded * 100 / periodStats.jobs).toFixed(2)))
                    }
                    {createBox(15, "10px", "O2D Jobs :", "black", getJobsOfType(periodStats.outcomesPerUser, "countO2D"))}
                    {createBox(15, "10px", "S2D Jobs :", "black", getJobsOfType(periodStats.outcomesPerUser, "countS2D"))}
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs>
                            {createExportEntrepriseButton(periodStats.entreprises, startMonthForName + "_" + startDate.getFullYear() + "-" +
                                endMonthForName + "_" + endDate.getFullYear(), periodStats.outcomesPerUser, classes.button)}
                        </Grid>
                        <Grid item xs>
                            {createExportUsersButton(periodStats.outcomesPerUser, startMonthForName + "_" + startDate.getFullYear() + "-" +
                                endMonthForName + "_" + endDate.getFullYear(), classes.button)}
                        </Grid>
                    </Grid>
                </div>
                :
                <div></div>
        );
    }

    function getNonBentleyEntreprises(entreprises: string[]) {
        let nonBentleyEntreprises = entreprises.filter(e => e !== BentleyUltimateSite);
        return nonBentleyEntreprises;
    }

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item xs={12} className={classes.gridBottomSpacing}>
                <Paper className={classes.paper}>
                    <Title>Jobs and Active Users per Month</Title>
                    <IconButton aria-label="hide Bentley usage" onClick={handleDiscardBentleyChange}>
                        <DiscardBentleyIcon />
                    </IconButton>
                    <DisplayChart statsReport={statsReport} discardBentley={discardBentley} />
                </Paper>
            </Grid>
            {
                statsReport.data ?
                    <Grid container direction="row" spacing={3} >
                        <Grid item xs>
                            <Paper className={classes.paper}>
                                {createBox(20, "10px", statsReport.data[statsReport.data.length - 3].month, "blue")}
                                {
                                    discardBentley ?
                                        <div></div> :
                                        createBox(15, "5px", "Bentley Users :", "black", statsReport.data[statsReport.data.length - 3].internalUsers.length)
                                }
                                {
                                    discardBentley ?
                                        createBox(15, "5px", "Entreprises", "black", getNonBentleyEntreprises(statsReport.data[statsReport.data.length - 3].uniqueEntreprises).length) :
                                        createBox(15, "5px", "Enterprises :", "black", statsReport.data[statsReport.data.length - 3].uniqueEntreprises.length)
                                }
                                {createBox(15, "5px", "External Users :", "black", statsReport.data[statsReport.data.length - 3].users.length -
                                    statsReport.data[statsReport.data.length - 3].internalUsers.length)}
                                {
                                    discardBentley ?
                                        createBox(15, "10px", "Jobs :", "black", getJobsWithoutBentley(statsReport.data[statsReport.data.length - 3])) :
                                        createBox(15, "10px", "Jobs :", "black", statsReport.data[statsReport.data.length - 3].numberOfJobs)
                                }
                                {createBox(15, "10px", "O2D Jobs :", "black", getJobsOfType(statsReport.data[statsReport.data.length - 3].outcomesPerUser, "countO2D"))}
                                {createBox(15, "10px", "S2D Jobs :", "black", getJobsOfType(statsReport.data[statsReport.data.length - 3].outcomesPerUser, "countS2D"))}
                                {
                                    discardBentley ?
                                        createBox(15, "10px", "% Success :", "black", parseFloat(
                                            (getJobsWithOutcome(statsReport.data[statsReport.data.length - 3].outcomesPerUser, "succeeded") * 100 /
                                                getJobsWithoutBentley(statsReport.data[statsReport.data.length - 3])).toFixed(2))) :
                                        createBox(15, "10px", "% Success :", "black", parseFloat(
                                            (getJobsWithOutcome(statsReport.data[statsReport.data.length - 3].outcomesPerUser, "succeeded") * 100 /
                                                statsReport.data[statsReport.data.length - 3].numberOfJobs).toFixed(2)))
                                }
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs>
                                        {createExportEntrepriseButton(statsReport.data[statsReport.data.length - 3].uniqueEntreprises,
                                            statsReport.data[statsReport.data.length - 3].month,
                                            statsReport.data[statsReport.data.length - 3].outcomesPerUser, classes.button)}
                                    </Grid>
                                    <Grid item xs>
                                        {createExportUsersButton(statsReport.data[statsReport.data.length - 3].outcomesPerUser, statsReport.data[statsReport.data.length - 3].month, classes.button)}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.paper}>
                                {createBox(20, "10px", statsReport.data[statsReport.data.length - 2].month, "blue")}
                                {
                                    discardBentley ?
                                        <div></div> :
                                        createBox(15, "5px", "Bentley Users :", "black", statsReport.data[statsReport.data.length - 2].internalUsers.length)
                                }
                                {
                                    discardBentley ?
                                        createBox(15, "5px", "Entreprises", "black", getNonBentleyEntreprises(statsReport.data[statsReport.data.length - 2].uniqueEntreprises).length) :
                                        createBox(15, "5px", "Enterprises :", "black", statsReport.data[statsReport.data.length - 2].uniqueEntreprises.length)
                                }
                                {createBox(15, "5px", "External Users :", "black", statsReport.data[statsReport.data.length - 2].users.length -
                                    statsReport.data[statsReport.data.length - 2].internalUsers.length)}
                                {
                                    discardBentley ?
                                        createBox(15, "10px", "Jobs :", "black", getJobsWithoutBentley(statsReport.data[statsReport.data.length - 2])) :
                                        createBox(15, "10px", "Jobs :", "black", statsReport.data[statsReport.data.length - 2].numberOfJobs)
                                }
                                {createBox(15, "10px", "O2D Jobs :", "black", getJobsOfType(statsReport.data[statsReport.data.length - 2].outcomesPerUser, "countO2D"))}
                                {createBox(15, "10px", "S2D Jobs :", "black", getJobsOfType(statsReport.data[statsReport.data.length - 2].outcomesPerUser, "countS2D"))}
                                {
                                    discardBentley ?
                                        createBox(15, "10px", "% Success :", "black", parseFloat(
                                            (getJobsWithOutcome(statsReport.data[statsReport.data.length - 2].outcomesPerUser, "succeeded") * 100 /
                                                getJobsWithoutBentley(statsReport.data[statsReport.data.length - 2])).toFixed(2))) :
                                        createBox(15, "10px", "% Success :", "black", parseFloat(
                                            (getJobsWithOutcome(statsReport.data[statsReport.data.length - 2].outcomesPerUser, "succeeded") * 100 /
                                                statsReport.data[statsReport.data.length - 2].numberOfJobs).toFixed(2)))
                                }
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs>
                                        {createExportEntrepriseButton(statsReport.data[statsReport.data.length - 2].uniqueEntreprises,
                                            statsReport.data[statsReport.data.length - 2].month,
                                            statsReport.data[statsReport.data.length - 2].outcomesPerUser,
                                            classes.button)}
                                    </Grid>
                                    <Grid item xs>
                                        {createExportUsersButton(statsReport.data[statsReport.data.length - 2].outcomesPerUser, statsReport.data[statsReport.data.length - 2].month, classes.button)}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.paper}>
                                {createBox(20, "10px", statsReport.data[statsReport.data.length - 1].month, "blue")}
                                {
                                    discardBentley ?
                                        <div></div> :
                                        createBox(15, "5px", "Bentley Users :", "black", statsReport.data[statsReport.data.length - 1].internalUsers.length)
                                }
                                {
                                    discardBentley ?
                                        createBox(15, "5px", "Entreprises", "black", getNonBentleyEntreprises(statsReport.data[statsReport.data.length - 1].uniqueEntreprises).length) :
                                        createBox(15, "5px", "Enterprises :", "black", statsReport.data[statsReport.data.length - 1].uniqueEntreprises.length)
                                }
                                {createBox(15, "5px", "External Users :", "black", statsReport.data[statsReport.data.length - 1].users.length -
                                    statsReport.data[statsReport.data.length - 1].internalUsers.length)}
                                {
                                    discardBentley ?
                                        createBox(15, "10px", "Jobs :", "black", getJobsWithoutBentley(statsReport.data[statsReport.data.length - 1])) :
                                        createBox(15, "10px", "Jobs :", "black", statsReport.data[statsReport.data.length - 1].numberOfJobs)
                                }
                                {createBox(15, "10px", "O2D Jobs :", "black", getJobsOfType(statsReport.data[statsReport.data.length - 1].outcomesPerUser, "countO2D"))}
                                {createBox(15, "10px", "S2D Jobs :", "black", getJobsOfType(statsReport.data[statsReport.data.length - 1].outcomesPerUser, "countS2D"))}
                                {
                                    discardBentley ?
                                        createBox(15, "10px", "% Success :", "black", parseFloat(
                                            (getJobsWithOutcome(statsReport.data[statsReport.data.length - 1].outcomesPerUser, "succeeded") * 100 /
                                                getJobsWithoutBentley(statsReport.data[statsReport.data.length - 1])).toFixed(2))) :
                                        createBox(15, "10px", "% Success :", "black", parseFloat(
                                            (getJobsWithOutcome(statsReport.data[statsReport.data.length - 1].outcomesPerUser, "succeeded") * 100 /
                                                statsReport.data[statsReport.data.length - 1].numberOfJobs).toFixed(2)))
                                }
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs>
                                        {createExportEntrepriseButton(statsReport.data[statsReport.data.length - 1].uniqueEntreprises,
                                            statsReport.data[statsReport.data.length - 1].month,
                                            statsReport.data[statsReport.data.length - 1].outcomesPerUser,
                                            classes.button)}
                                    </Grid>
                                    <Grid item xs>
                                        {createExportUsersButton(statsReport.data[statsReport.data.length - 1].outcomesPerUser, statsReport.data[statsReport.data.length - 1].month, classes.button)}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.paper}>
                                <form noValidate autoComplete="off">
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    format="dd/MM/yyyy"
                                                    label="Start Date"
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    format="dd/MM/yyyy"
                                                    label="End Date"
                                                    value={endDate}
                                                    onChange={handleEndDateChange}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <Button variant="contained" color="secondary" className={classes.button} onClick={submitPeriodStats}>
                                        Get period stats
                                    </Button>
                                </form>
                                <DisplayPeriodStats />
                            </Paper>
                        </Grid>
                    </Grid> :
                    <div></div>
            }

        </Grid>
    );
}
