import * as React from 'react';
import { useFetchApi } from '../utils/UseFetchApi';
import { useJobActions, IInput, IOutput, IJobDetailed, JobState, JobOutcome, getJobSettings } from '../Model/Job';
import CenteredProgress from './CenteredProgress';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles, withStyles } from '@mui/styles';
import {
    LinearProgress,
    Table,
    TableRow,
    TableCell,
    Button,
    Typography,
    TableBody,
    CircularProgress,
    TableContainer,
    Grid,
    Box,
} from "@mui/material";
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import { IJobSteps, IJobStep } from '../Model/JobSteps';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useInterval from '../utils/UseInterval';
import CancelOutlineIcon from '@mui/icons-material/CancelOutlined';
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';
import { dateStringToLocaleString } from '../utils/DateUtils';
import JobStepDetails from './JobStepDetails';
import { green, red, grey, orange } from '@mui/material/colors';
import { downloadUri } from '../utils/Utils';
import { BlockOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { JobStepsRefreshInterval } from '../utils/Constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { backgroundColor: theme.palette.background.paper, },
        spacing: { padding: theme.spacing(1), },
        table: {},
        row: {
            border: '0px'
        },
        cell: {
            border: '0px'
        }
    }),
);

const BorderLinearProgress = withStyles({
    root: {
        height: 25,
        width: 600,
        borderRadius: 20
    },
    bar: {
        borderRadius: 20,
    },
})(LinearProgress);

enum ETypography {
    None,
    Standard,
    NoWrap,
}

interface IJobRow {
    label: string;
    fill: any;
    typography: ETypography;
    showLoader?: boolean;
}

interface SasUri {
    sasUri: string;
}

function JobInfoDisplay(props: { jobId: string }) {
    const jobStepsFetch = useFetchApi<IJobSteps>(`${window.location.origin}/api/v1/jobs/${props.jobId}/steps`);
    const runningTaskApi = useFetchApi<any>(`${window.location.origin}/api/v1/jobs/${props.jobId}/runningTasks`);
    const failedTaskApi = useFetchApi<any>(`${window.location.origin}/api/v1/jobs/${props.jobId}/failedTasks`);

    const jobStorageSas = useFetchApi<SasUri>(`${window.location.origin}/api/v1/jobs/${props.jobId}/sas-uri`);
    const jobDetailed = useFetchApi<IJobDetailed>(`${window.location.origin}/api/v1/jobs/${props.jobId}/detailed`);
    
    const realityDataNameApi = useFetchApi<string>();

    const [selectedStep, setSelectedStep] = React.useState<IJobStep | undefined>(undefined);
    const classes = useStyles();
    const jobActions = useJobActions();

    const [runningTasksInfo, setRunningTasksInfo] = React.useState<string>("Not yet queried");
    const [failedTasksInfo, setFailedTasksInfo] = React.useState<string[] | string>("Not yet queried");

    React.useEffect(() => {
        jobStorageSas.run();
        jobDetailed.run();
    }, []);

    function DoFetch() {
        return jobDetailed.data && jobDetailed.data?.state !== JobState.Unsubmitted && (!jobStepsFetch.data || jobStepsFetch.data.activeIndex !== -1);
    }

    useInterval(() => {
        if (jobStepsFetch.error) {
            return;
        }
        jobStepsFetch.run();
    }, DoFetch() ? JobStepsRefreshInterval : null);

    function GetButtons(job: IJobDetailed) {
        var butLogs = (<Button variant="contained" color="primary" size="small"
            startIcon={<GetAppSharpIcon />} disableElevation onClick={(event) => handleDownloadJobLogs(event)}>
            Logs
        </Button>);
        var butKill = (<Button variant="contained" style={{ color: grey[900], backgroundColor: orange[200] }} size="small"
            startIcon={<CancelOutlineIcon />} disableElevation onClick={() => jobActions.terminate(job)}>
            Terminate
        </Button>);

        return (
            <div>
                {job.state !== JobState.Unsubmitted && butLogs}
                &nbsp;
                {job.state === JobState.Active && butKill}
            </div>
        );
    }

    const handleClick = (event: React.MouseEvent<unknown>, step: IJobStep) => {
        setSelectedStep(step);
    };

    const handleDownloadJobLogs = (event: React.MouseEvent<unknown>) => {
        jobActions.downloadLogs(jobDetailed.data!);
    };

    const handleDownloadTaskLogs = (taskId: string) => {
        const taskLog = `/${taskId}/$TaskLog/Task_${taskId}.log`;
        let uri = jobStorageSas.data?.sasUri.replace("?", `${taskLog}?`) ?? "";
        downloadUri(uri);
    };

    const getRunningTasks = (event: React.MouseEvent<unknown>) => {
        runningTaskApi.run()
            .then(result => {
                if (result.length > 0)
                    setRunningTasksInfo(`${result.length} tasks running: ${result.join(", ")}`);
                else
                    setRunningTasksInfo("No running task");
            })
            .catch(error => {
                setRunningTasksInfo(`Failed to get running tasks: ${error.toString()}`);
            });
    };

    function runningTasks() {
        return (<span><Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => getRunningTasks(event)}>
            Get running tasks
        </Button>&nbsp;{runningTasksInfo}</span>)
    }

    const getFailedTasks = (event: React.MouseEvent<unknown>) => {
        failedTaskApi.run()
        .then(result => {
            if (result.length > 0)
                setFailedTasksInfo(result);
            else
                setFailedTasksInfo("No failed task");
        })
        .catch(error => {
            setFailedTasksInfo(`Failed to get failed tasks: ${error.toString()}`);
        });
    };

    function failedTasks() {
        let message: string | JSX.Element;

        if (typeof(failedTasksInfo) === 'string')
            message = failedTasksInfo
        else {
            message = (
                <span>
                    {failedTasksInfo.map(t =>
                        <Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => handleDownloadTaskLogs(t.substring(0, t.indexOf(':')))}>
                            {t}
                        </Button>
                    )}
                </span>);
        }

        return (<span><Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => getFailedTasks(event)}>
            Get failed tasks
        </Button>&nbsp;{message}</span>)
    }

    if (jobDetailed.isFetching && !jobDetailed.data)
        return (<CenteredProgress />);

    if (jobDetailed.error)
        return (<div>{jobDetailed.error}</div>);

    if (!jobDetailed.data)
        return (<div>{`Job(${props.jobId}) not found!`}</div>);


    function getDuration(timeInMilliseconds: number) {
        let seconds = Math.floor((timeInMilliseconds / 1000) % 60),
            minutes = Math.floor((timeInMilliseconds / (1000 * 60)) % 60),
            hours = Math.floor((timeInMilliseconds / (1000 * 60 * 60)) % 24),
            days = Math.floor((timeInMilliseconds / (1000 * 60 * 60 * 24)));
        
        let formatted = `${minutes}m ${seconds}s`;

        if (days > 0) {
            formatted = `${days}d ${hours}h ` + formatted;
        } else if (hours > 0) {
            formatted = `${hours}h ` + formatted;
        }

        return formatted;
    }

    function passCheckDisplay(job: IJobDetailed) {
        const outcome = job.executionInformation?.outcome ?? "N/A";
        if (outcome === JobOutcome.Success)
            return <CheckCircleOutlineSharpIcon fontSize="large" style={{ color: green[500] }} />;
        else if (outcome === JobOutcome.Failed)
            return <HighlightOffSharpIcon fontSize="large" style={{ color: red[500] }} />;
        else if (outcome === JobOutcome.Cancelled)
            return <BlockOutlined fontSize="large" style={{ color: grey[500] }} />;
        else
            return <LoopSharpIcon fontSize="large" color="secondary" />;
    }

    function stepDisplayTable() {
        if (!jobStepsFetch.data) {
            if (jobStepsFetch.isFetching) return <CenteredProgress />;
            else {
              jobStepsFetch.run();
              return;
            }
          }
      
          if (jobStepsFetch.error)
              return (<div>{jobStepsFetch.error}</div>);
      
          if (!jobStepsFetch.data)
            return (<div>{`Job(${props.jobId}) steps not found.`}</div>);
        
        return (
            <TableContainer >
                <Table className={classes.table} size="small" >
                    <TableBody>
                        {jobStepsFetch.data?.steps.map((jobStep, index) =>
                            <TableRow
                                key={index}
                                className={classes.row}
                                selected={selectedStep === jobStep}
                                onClick={(event) => handleClick(event, jobStep)}
                            >
                                <TableCell className={classes.cell} style={{ width: 256 }}>
                                    {stepDisplay(jobStep, index === jobStepsFetch.data?.activeIndex)}
                                </TableCell>
                                <TableCell className={classes.cell} >
                                    <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: "1100px" }}>
                                        <Box sx={{ width: '100%', mr: 1.25 }}>
                                            <LinearProgress variant="determinate" color="secondary" value={jobStep.percentage} />
                                        </Box>
                                        <Box sx={{ width: 40 }}>
                                            {index === jobStepsFetch.data?.activeIndex && <strong>{Math.round(jobStep.percentage * 100) / 100}%</strong>}
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow >
                        )}
                    </TableBody>
                </Table>
                {selectedStep ? <JobStepDetails jobId={props.jobId} stepName={selectedStep.name} /> : <div></div>}
            </TableContainer>
        )
    }

    function stepDisplay(step: IJobStep, isActive: boolean) {
        const stepText = `${step.name} (${step.taskCount ?? '?'})`;
        if (isActive)
            return <strong>{stepText}</strong>;
        return stepText;
    }

    function createRow(jobRow: IJobRow)
    {
        let filling: any;
        switch (jobRow.typography)
        {
            case ETypography.None:
                filling = jobRow.fill;
                break;

            case ETypography.Standard:
                filling = <Typography>{jobRow.fill}</Typography>;
                break;

            case ETypography.NoWrap:
                filling= <Typography noWrap>{jobRow.fill}</Typography>;
                break;
        }

        return (
            <TableRow className={classes.row} key={jobRow.label}>
                <TableCell className={classes.cell}>
                    <strong>{jobRow.label}</strong>
                </TableCell>
                <TableCell className={classes.cell}>
                    {jobRow.showLoader ? <CircularProgress size={16} /> : filling}
                </TableCell>
            </TableRow>
        );
    }

    function resultDetailsDisplay(job: IJobDetailed) {
        let startTime = job.executionInformation?.startedDateTime;
        let endTime = job.executionInformation?.endedDateTime;
        var runTime = -1;
        var computeTime = job.privateExecutionInformation?.computeTime ?? -1;
        
        if (endTime && startTime) {
            runTime = Date.parse(endTime) - Date.parse(startTime);
        }
        endTime = endTime ? dateStringToLocaleString(endTime) : "N/A";
        startTime = startTime ? dateStringToLocaleString(startTime) : "N/A";

        const resultRows: IJobRow[] = [
            { label: "Start Time:", fill: startTime, typography: ETypography.Standard, showLoader: job.state === JobState.Active && startTime === "N/A" },
            { label: "End Time:", fill: endTime, typography: ETypography.Standard, showLoader: job.state === JobState.Active },
            { label: "Run Time:", fill: runTime === -1 ? "N/A" : getDuration(runTime), typography: ETypography.Standard, showLoader: job.state === JobState.Active },
            { label: "Compute Time:", fill: computeTime === -1 ? "N/A" : getDuration(computeTime * 1000), typography: ETypography.Standard, showLoader: job.state === JobState.Active },
            { label: "Exit Code:", fill: job.executionInformation?.exitCode ?? "N/A", typography: ETypography.Standard, showLoader: job.state === JobState.Active },
        ];

        return (
            <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {resultRows.map((resultRow) => createRow(resultRow))}
                </TableBody>
            </Table>
        )
    }

    function jobSettingsDisplay() {
        const baseSettings = getJobSettings(jobDetailed.data!);

        const detectorInput = baseSettings?.inputs.filter((input: IInput) => input.name.toLowerCase().includes("detector")) ?? null;
        let detector = detectorInput != null ? detectorInput[0] : null;

        // This is the detector's complete identifier with name and version.
        let detectorId = detector?.detectorId;
        let source = detector?.source;
        let isLibraryDetector = source && source?.toLowerCase()?.includes("library");
        if (isLibraryDetector) {
            // This is a library detector. Its value could be null for unsubmitted job.
            // If source contains 'library', we are sure that this is a library detector. So the id should be in the realityDataId field or detectorId field.
            detectorId = detectorId ?? detector?.realityDataId;
        }

        // DetectorId was not found. Run the frontendhelper API endpoint to get the name.
        if (detector && !detectorId) {
            if (realityDataNameApi.isFetching) return <CenteredProgress />;
            else if (!realityDataNameApi.hasData) {
                realityDataNameApi.run(window.location.origin + `/api/v1/frontendhelper/realityDataName?id=${detector?.realityDataId ?? ""}&iTwinId=${jobDetailed.data?.iTwinId ?? ""}`);
                return;
            }
        }

        const outputs: IOutput[] = baseSettings?.outputs ?? [];
        let outputFormats: string[] = outputs.map(o => o.name);

        const settingsRows: IJobRow[] = [
            { label: "Output Formats:", fill: outputFormats.join(", "), typography: ETypography.Standard },
            {
                label: "Detectors:",
                fill: detectorId ? <>{detectorId} {isLibraryDetector && (<i>(Library Detector)</i>)}</>
                    : (realityDataNameApi.data ?? "N/A"),
                typography: ETypography.Standard
            }
        ];

        return (
            <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {settingsRows.map((settingsRow) => createRow(settingsRow))}
                </TableBody>
            </Table>
        )
    }

    function inputDetailsList(inputs: IInput[]) {
        const listItems = inputs.map((input, index) => {
            const inputRows: IJobRow[] = [
                { label: "Id:", fill: input.realityDataId, typography: ETypography.NoWrap },
                { label: "Type:", fill: input.name, typography: ETypography.NoWrap }
            ];

            return(
                <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }} key={input.realityDataId}>
                    <TableBody>
                        <TableRow className={classes.row}>
                            <Typography variant="h6">
                                Input {index + 1}:
                            </Typography>
                        </TableRow>
                        {inputRows.map((inputRow) => createRow(inputRow))}
                    </TableBody>
                </Table>
            );
        });
        return (
            <div>{listItems}</div>
        );
    }

    function outputDetailsList(outputs: IOutput[]) {
        const listItems = outputs.map((output, index) =>
        {
            const outputRows: IJobRow[] = [
                { label: "Id:", fill: output.realityDataId, typography: ETypography.NoWrap },
                { label: "Type:", fill: output.name, typography: ETypography.NoWrap }
            ];

            return (
                <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }} key={output.name}>
                    <TableBody>
                        <TableRow className={classes.row}>
                            <Typography variant="h6">
                                Output {index + 1}:
                            </Typography>
                        </TableRow>
                        {outputRows.map((outputRow) => createRow(outputRow))}
                    </TableBody>
                </Table>
            );
        });
        return (
            <div>{listItems}</div>
        );
    }

    function inputOutputDisplay() {
        const baseSettings = getJobSettings(jobDetailed.data!);
        const inputs: IInput[] = baseSettings?.inputs ?? [];
        const outputs: IOutput[] = baseSettings?.outputs ?? [];

        return (
            <div>
                {inputDetailsList(inputs)}
                {outputDetailsList(outputs)}
            </div>
        );
    }

    function jobDetailsAccordion() {
        return (
            <>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Results and Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} direction="row">
                            <Grid item md={6}>
                                {resultDetailsDisplay(jobDetailed.data!)}
                            </Grid>
                            <Grid item md={6}>
                                {jobSettingsDisplay()}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Running tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {runningTasks()}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Failed tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {failedTasks()}
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Step Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {jobDetailed.data?.state !== JobState.Unsubmitted ? stepDisplayTable() : "Job not submitted yet."}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">Input & Output Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {inputOutputDisplay()}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    function submissionDetailsDisplay(job: IJobDetailed) {
        let submissionTime = job.executionInformation?.submissionDateTime;
        let startTime = job.executionInformation?.startedDateTime;
        let waitTime = -1;
        
        if (submissionTime && startTime) {
            waitTime = Date.parse(startTime) - Date.parse(submissionTime);
        }
        submissionTime = submissionTime ? dateStringToLocaleString(submissionTime) : "N/A";
        startTime = startTime ? dateStringToLocaleString(startTime) : "N/A";

        const submissionRows: IJobRow[] = [
            { label: "Email:", fill: job.email, typography: ETypography.Standard },
            { label: "Creation Time:", fill: dateStringToLocaleString(job.createdDateTime), typography: ETypography.NoWrap },
            { label: "User-Agent:", fill: job.privateSubmissionDetails?.userAgent ?? "N/A", typography: ETypography.Standard },
            { label: "Pool:", fill: job.privateSubmissionDetails?.clusterId ?? "N/A", typography: ETypography.Standard },
            { label: "Submission Time:", fill: submissionTime, typography: ETypography.NoWrap },
            { label: "Start Time:", fill: startTime, typography: ETypography.NoWrap, showLoader: job.state === JobState.Active && startTime === "N/A" },
            { label: "Wait Time:", fill: waitTime === -1 ? "N/A" : getDuration(waitTime), typography: ETypography.NoWrap, showLoader: job.state === JobState.Active && startTime === "N/A" }
        ];

        return (
            <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <TableRow className={classes.row}>
                        <Typography variant="h6">
                            Submission Details:
                        </Typography>
                    </TableRow>
                    {submissionRows.map((submissionRow) => createRow(submissionRow))}
                </TableBody>
            </Table>
        )
    }

    function jobInfoHeader(job: IJobDetailed) {
        const infoRows: IJobRow[] = [
            { label: "Id:", fill: job.id, typography: ETypography.NoWrap },
            { label: "Type:", fill: job.type, typography: ETypography.NoWrap },
            { label: "iTwin:", fill: job.iTwinId ?? "N/A", typography: ETypography.NoWrap },
            { label: "State:", fill: job.state, typography: ETypography.NoWrap },
            { label: "Outcome:", fill: job.executionInformation?.outcome ?? "N/A", typography: ETypography.NoWrap, showLoader: job.state === JobState.Active },
            { label: "Location:", fill: job.dataCenter.location, typography: ETypography.NoWrap }
        ];
        
        if (job.state !== JobState.Unsubmitted) {
            infoRows.push({ label: "Actions:", fill: GetButtons(job), typography: ETypography.None });
        }
        
        return (
            <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <TableRow className={classes.row}>
                        <Typography variant="h6">
                            Job Details:
                        </Typography>
                    </TableRow>
                    {infoRows.map((infoRow) => createRow(infoRow))}
                </TableBody>
            </Table>
        )
    }

    return (
        <div className={classes.spacing}>
            <TableContainer >
                <Table className={classes.table} size="small" style={{ width: "auto", tableLayout: "auto" }}>
                    <TableCell className={classes.cell}>
                        {passCheckDisplay(jobDetailed.data)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <Typography noWrap variant="h4" style={{ width: 264 }}>
                            {jobDetailed.data?.name}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.cell} >
                        <Typography>
                            {jobStepsFetch.hasData && <BorderLinearProgress variant="determinate" color="secondary" value={jobStepsFetch.data?.percentage} />}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <Typography noWrap variant="h5" style={{ width: 90 }}>
                            {jobStepsFetch.hasData && `${jobStepsFetch.data?.percentage}%`}
                        </Typography>
                    </TableCell>
                </Table>
                <Table className={classes.table} size="small">
                    <TableCell className={classes.cell}>
                        {jobInfoHeader(jobDetailed.data)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {submissionDetailsDisplay(jobDetailed.data)}
                    </TableCell>
                </Table>
            </TableContainer>
            {jobDetailsAccordion()}
        </div>
    );
}

export default function JobDetails(props: any) {
    const classes = useStyles();
    const { jobId } = useParams();

    if (!jobId)
        return (<div>{`Job ID is required in params.`}</div>);

    return (
        <div className={classes.root}>
            <div className={classes.spacing}>
                <JobInfoDisplay jobId={jobId} />
            </div>
        </div>
    );
}
