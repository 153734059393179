import * as React from 'react';
import { Grid } from '@mui/material';
import ClusterMap from './ClusterMap';
import ActiveJobs from './ActiveJobs';
import CompletedJobs from './CompletedJobs';
import { styled } from '@mui/system';

export interface ICoordinate {
    longitude: number;
    latitude: number;
}

const StyledDiv = styled('div')(() => ({
    flexGrow: 1,
}))

const StyledGrid = styled(Grid)((props) => ({
    padding: props.theme.spacing(0.5),
    height: "100%",
}))



function Dashboard() {

    return (
        <StyledDiv >
            <StyledGrid container >
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Grid container>
                        <StyledGrid item xs={12} >
                            <ClusterMap />
                        </StyledGrid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                    <Grid container>
                        <StyledGrid item xs={12} >
                            <ActiveJobs />
                        </StyledGrid>
                        <StyledGrid item xs={12} >
                            <CompletedJobs />
                        </StyledGrid>
                    </Grid>
                </Grid>
            </StyledGrid>
        </StyledDiv>
    );
};

export default Dashboard;


