import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TextField, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useJobExamples, IDataCenterJobExamples } from './JobExamples';
import MenuButton from './MenuButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetchApi } from '../utils/UseFetchApi'
import { IJobResponse, IJob, IJobPatch } from '../Model/Job';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
        >
            <Box p={1}>{children}</Box>
        </Typography>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        button: {
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        textField: {
            backgroundColor: theme.palette.background.paper,
        },
        progress: {
            margin: theme.spacing(2),

        },
        centeredProgress: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
        }
    }),
);

function CenteredProgress() {
    const classes = useStyles();
    return (
        <div className={classes.centeredProgress}>
            <CircularProgress className={classes.progress} />
        </div>
    );
}

function SubmitJobPanel(props: { jobExamples: IDataCenterJobExamples[] }) {
    const classes = useStyles();
    const theme = useTheme();
    const [locationIndex, setLocationIndex] = React.useState(0);
    const [jobIndex, setJobIndex] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [currentJob, setCurrentJob] = React.useState(JSON.stringify(props.jobExamples[0].jobs[0].job, null, '\t'));
    const fetchApi = useFetchApi<IJobResponse>();

    function handleIndexChange(index: number) {
        setJobIndex(index);
        UpdateCurrentJob(locationIndex, index);
    }

    function onJobIndexChanged(index: number) {
        setJobIndex(index);
        UpdateCurrentJob(locationIndex, index);
    }

    function onLocationChanged(index: number) {
        setLocationIndex(index);
        setJobIndex(0);
        UpdateCurrentJob(index, 0);
    }

    function UpdateCurrentJob(locIndex: number, jIndex: number) {
        setCurrentJob(JSON.stringify(props.jobExamples[locIndex].jobs[jIndex].job, null, '\t'));
    }

    const onPayloadContentChanged = (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentJob(event.target.value);
    }

    function createAndSubmitJob() {
        enqueueSnackbar("Creating Job: " + JSON.parse(currentJob).name, { variant: "info" });

        fetchApi.run(window.location.origin + "/api/v1/jobs",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: currentJob
            })
            .then(result => {
                if (!result)
                    throw Error("unknown error");

                enqueueSnackbar("Job " + result.job.id + " successfully created!", { variant: "success" });
                submitJob(result.job.id);
            })
            .catch(error => {
                enqueueSnackbar("Job create failed. " + error.toString(), { variant: "error" });
            });
    }

    function submitJob(id: string) {
        enqueueSnackbar("Submitting Job", { variant: "info" });
        const patch: IJobPatch = {
            state: "active"
        };

        fetchApi.run(window.location.origin + "/api/v1/jobs/" + id,
            {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(patch, null, '\t')
            })
            .then(result => {
                if (!result)
                    throw Error("unknown error");

                enqueueSnackbar("Job " + result.job.id + " successfully submitted!", { variant: "success" });
            })
            .catch(error => {
                enqueueSnackbar("Job submit failed. " + error.toString(), { variant: "error" });
            });

    }

    return (
        <div className={classes.root}>
            <MenuButton key={locationIndex} index={locationIndex} choices={Array.from(props.jobExamples, l => l.dataCenter)} onChanged={onLocationChanged} />
            <MenuButton key={`${locationIndex}_${jobIndex}`} index={jobIndex} choices={Array.from(props.jobExamples[locationIndex].jobs, ex => ex.label)} onChanged={onJobIndexChanged} />
            <Button variant="contained" color="secondary" className={classes.button} onClick={createAndSubmitJob}>
                Submit Job
            </Button>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={jobIndex}
                onChangeIndex={handleIndexChange}
            >
                {props.jobExamples[locationIndex].jobs.map((jobEx, index) =>
                    <TabPanel key={index} value={index} index={index} dir={theme.direction}>
                        <TextField key={index}
                            id="job-payload"
                            label="Payload"
                            multiline
                            value={currentJob}
                            className={classes.textField}
                            onChange={onPayloadContentChanged(index)}
                            margin="normal"
                            fullWidth={true}
                            variant="outlined"
                        />
                    </TabPanel>)}
            </SwipeableViews>
        </div>
    );
}

export default function SubmitJob() {
    const classes = useStyles();
    const jobExamples = useJobExamples();

    return (
        <div className={classes.root}>
            {jobExamples.data as IDataCenterJobExamples[] ? <SubmitJobPanel jobExamples={jobExamples.data as IDataCenterJobExamples[]} /> : <CenteredProgress />}
        </div>);
}

