import * as React from 'react';
import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { useFetchApi } from '../utils/UseFetchApi';
import { ITask } from '../Model/TaskGraph';
import MaterialTable from '@material-table/core';
import { toArray, downloadUris } from '../utils/Utils';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { backgroundColor: theme.palette.background.paper, },
        spacing: { padding: theme.spacing(1), },
        table: {},
        row: {
            border: '0px'
        },
        cell: {
            border: '0px'
        }
    }),
);

interface SasUri {
    sasUri: string;
}

export default function JobStepDetails(props: { jobId: string, stepName: string }) {
    const classes = useStyles();
    const jobStorageSas = useFetchApi<SasUri>(`${window.location.origin}/api/v1/jobs/${props.jobId}/sas-uri`);
    const taskRequest = useFetchApi<ITask[]>(`${window.location.origin}/api/v1/jobs/${props.jobId}/steps/${props.stepName}`);

    function getLogLink(maybeTask: ITask[] | ITask) {
        const taskArray = toArray(maybeTask);

        if (taskArray.length === 0 || !jobStorageSas?.data?.sasUri)
            return undefined;
        var uriArray: string[] = [];
        taskArray.forEach((task: ITask) => {
            const taskLog = `/${task.id}/$TaskLog/Task_${task.id}.log`;
            var uri = jobStorageSas?.data?.sasUri.replace("?", `${taskLog}?`) ? jobStorageSas?.data?.sasUri.replace("?", `${taskLog}?`) : "";
            uriArray.push(uri);
        })

        downloadUris(uriArray);
    }

    function getAllLogs() {
        getLogLink(!taskRequest.data || taskRequest.error ? [] : taskRequest.data);
    }

    const handleClick = (event: React.MouseEvent<unknown>) => {
        getAllLogs();
    };

    React.useEffect(() => {
        jobStorageSas.run();
        taskRequest.run();
    }, [props.stepName]);

    return (
        <div className={classes.root} >
            <MaterialTable<ITask>
                title={`${props.stepName} Tasks`}
                isLoading={taskRequest.isFetching}
                columns={[
                    { title: "Id", field: "id", sorting: true },
                    { title: "Type", field: "type", sorting: true },
                    { title: "Weight", field: "weight", sorting: true, render: rowData => <div>{Number(rowData.weight).toFixed(4)}</div> },
                ]}
                data={!taskRequest.data || taskRequest.error ? [] : taskRequest.data}
                actions={[
                    rowData => ({
                        tooltip: 'Download logs',
                        icon: 'get_app',
                        onClick: (_, rowData) => getLogLink(rowData)
                    })
                ]}
                options={{
                    filtering: false,
                    search: true,
                    sorting: true,
                    headerStyle: { backgroundColor: taskRequest.error ? '#FF0000' : '#9BA5AE', color: '#000000' },
                    padding: 'dense'
                }}
            />
            <Button variant="contained" color="primary" disableElevation onClick={(event) => handleClick(event)}>
                Download Step Logs
            </Button>
        </div>
    );
}
